/* Deafult Margin & Padding */
/*-- Margin Top --*/
.mt-5 {
	margin-top: 5px;
}
.mt-10 {
	margin-top: 10px;
}
.mt-15 {
	margin-top: 15px;
}
.mt-20 {
	margin-top: 20px;
}
.mt-25 {
	margin-top: 25px;
}
.mt-30 {
	margin-top: 30px;
}
.mt-35 {
	margin-top: 35px;
}
.mt-40 {
	margin-top: 40px;
}
.mt-45 {
	margin-top: 45px;
}
.mt-50 {
	margin-top: 50px;
}
.mt-55 {
	margin-top: 55px;
}
.mt-60 {
	margin-top: 60px;
}
.mt-65 {
	margin-top: 65px;
}
.mt-70 {
	margin-top: 70px;
}
.mt-75 {
	margin-top: 75px;
}
.mt-80 {
	margin-top: 80px;
}
.mt-85 {
	margin-top: 85px;
}
.mt-90 {
	margin-top: 90px;
}
.mt-95 {
	margin-top: 95px;
}
.mt-100 {
	margin-top: 100px;
}
.mt-105 {
	margin-top: 105px;
}
.mt-110 {
	margin-top: 110px;
}
.mt-115 {
	margin-top: 115px;
}
.mt-120 {
	margin-top: 120px;
}
.mt-125 {
	margin-top: 125px;
}
.mt-130 {
	margin-top: 130px;
}
.mt-135 {
	margin-top: 135px;
}
.mt-140 {
	margin-top: 140px;
}
.mt-145 {
	margin-top: 145px;
}
.mt-150 {
	margin-top: 150px;
}
.mt-155 {
	margin-top: 155px;
}
.mt-160 {
	margin-top: 160px;
}
.mt-165 {
	margin-top: 165px;
}
.mt-170 {
	margin-top: 170px;
}
.mt-175 {
	margin-top: 175px;
}
.mt-180 {
	margin-top: 180px;
}
.mt-185 {
	margin-top: 185px;
}
.mt-190 {
	margin-top: 190px;
}
.mt-195 {
	margin-top: 195px;
}
.mt-200 {
	margin-top: 200px;
}
/*-- Margin Bottom --*/

.mb-5 {
	margin-bottom: 5px;
}
.mb-10 {
	margin-bottom: 10px;
}
.mb-15 {
	margin-bottom: 15px;
}
.mb-20 {
	margin-bottom: 20px;
}
.mb-25 {
	margin-bottom: 25px;
}
.mb-30 {
	margin-bottom: 30px;
}
.mb-35 {
	margin-bottom: 35px;
}
.mb-40 {
	margin-bottom: 40px;
}
.mb-45 {
	margin-bottom: 45px;
}
.mb-50 {
	margin-bottom: 50px;
}
.mb-55 {
	margin-bottom: 55px;
}
.mb-60 {
	margin-bottom: 60px;
}
.mb-65 {
	margin-bottom: 65px;
}
.mb-70 {
	margin-bottom: 70px;
}
.mb-75 {
	margin-bottom: 75px;
}
.mb-80 {
	margin-bottom: 80px;
}
.mb-85 {
	margin-bottom: 85px;
}
.mb-90 {
	margin-bottom: 90px;
}
.mb-95 {
	margin-bottom: 95px;
}
.mb-100 {
	margin-bottom: 100px;
}
.mb-105 {
	margin-bottom: 105px;
}
.mb-110 {
	margin-bottom: 110px;
}
.mb-115 {
	margin-bottom: 115px;
}
.mb-120 {
	margin-bottom: 120px;
}
.mb-125 {
	margin-bottom: 125px;
}
.mb-130 {
	margin-bottom: 130px;
}
.mb-135 {
	margin-bottom: 135px;
}
.mb-140 {
	margin-bottom: 140px;
}
.mb-145 {
	margin-bottom: 145px;
}
.mb-150 {
	margin-bottom: 150px;
}
.mb-155 {
	margin-bottom: 155px;
}
.mb-160 {
	margin-bottom: 160px;
}
.mb-165 {
	margin-bottom: 165px;
}
.mb-170 {
	margin-bottom: 170px;
}
.mb-175 {
	margin-bottom: 175px;
}
.mb-180 {
	margin-bottom: 180px;
}
.mb-185 {
	margin-bottom: 185px;
}
.mb-190 {
	margin-bottom: 190px;
}
.mb-195 {
	margin-bottom: 195px;
}
.mb-200 {
	margin-bottom: 200px;
}
/*-- Padding Top --*/

.pt-5 {
	padding-top: 5px;
}
.pt-10 {
	padding-top: 10px;
}
.pt-15 {
	padding-top: 15px;
}
.pt-20 {
	padding-top: 20px;
}
.pt-25 {
	padding-top: 25px;
}
.pt-30 {
	padding-top: 30px;
}
.pt-35 {
	padding-top: 35px;
}
.pt-40 {
	padding-top: 40px;
}
.pt-45 {
	padding-top: 45px;
}
.pt-50 {
	padding-top: 50px;
}
.pt-55 {
	padding-top: 55px;
}
.pt-60 {
	padding-top: 60px;
}
.pt-65 {
	padding-top: 65px;
}
.pt-70 {
	padding-top: 70px;
}
.pt-75 {
	padding-top: 75px;
}
.pt-80 {
	padding-top: 80px;
}
.pt-85 {
	padding-top: 85px;
}
.pt-90 {
	padding-top: 90px;
}
.pt-95 {
	padding-top: 95px;
}
.pt-100 {
	padding-top: 100px;
}
.pt-105 {
	padding-top: 105px;
}
.pt-110 {
	padding-top: 110px;
}
.pt-115 {
	padding-top: 115px;
}
.pt-120 {
	padding-top: 120px;
}
.pt-125 {
	padding-top: 125px;
}
.pt-130 {
	padding-top: 130px;
}
.pt-135 {
	padding-top: 135px;
}
.pt-140 {
	padding-top: 140px;
}
.pt-145 {
	padding-top: 145px;
}
.pt-150 {
	padding-top: 150px;
}
.pt-155 {
	padding-top: 155px;
}
.pt-160 {
	padding-top: 160px;
}
.pt-165 {
	padding-top: 165px;
}
.pt-170 {
	padding-top: 170px;
}
.pt-175 {
	padding-top: 175px;
}
.pt-180 {
	padding-top: 180px;
}
.pt-185 {
	padding-top: 185px;
}
.pt-190 {
	padding-top: 190px;
}
.pt-195 {
	padding-top: 195px;
}
.pt-200 {
	padding-top: 200px;
}
.pt-113 {
	padding-top: 113px;
}
/*-- Padding Bottom --*/

.pb-5 {
	padding-bottom: 5px;
}
.pb-10 {
	padding-bottom: 10px;
}
.pb-15 {
	padding-bottom: 15px;
}
.pb-20 {
	padding-bottom: 20px;
}
.pb-25 {
	padding-bottom: 25px;
}
.pb-30 {
	padding-bottom: 30px;
}
.pb-35 {
	padding-bottom: 35px;
}
.pb-40 {
	padding-bottom: 40px;
}
.pb-45 {
	padding-bottom: 45px;
}
.pb-50 {
	padding-bottom: 50px;
}
.pb-55 {
	padding-bottom: 55px;
}
.pb-60 {
	padding-bottom: 60px;
}
.pb-65 {
	padding-bottom: 65px;
}
.pb-70 {
	padding-bottom: 70px;
}
.pb-75 {
	padding-bottom: 75px;
}
.pb-80 {
	padding-bottom: 80px;
}
.pb-85 {
	padding-bottom: 85px;
}
.pb-90 {
	padding-bottom: 90px;
}
.pb-95 {
	padding-bottom: 95px;
}
.pb-100 {
	padding-bottom: 100px;
}
.pb-105 {
	padding-bottom: 105px;
}
.pb-110 {
	padding-bottom: 110px;
}
.pb-115 {
	padding-bottom: 115px;
}
.pb-120 {
	padding-bottom: 120px;
}
.pb-125 {
	padding-bottom: 125px;
}
.pb-130 {
	padding-bottom: 130px;
}
.pb-135 {
	padding-bottom: 135px;
}
.pb-140 {
	padding-bottom: 140px;
}
.pb-145 {
	padding-bottom: 145px;
}
.pb-150 {
	padding-bottom: 150px;
}
.pb-155 {
	padding-bottom: 155px;
}
.pb-160 {
	padding-bottom: 160px;
}
.pb-165 {
	padding-bottom: 165px;
}
.pb-170 {
	padding-bottom: 170px;
}
.pb-175 {
	padding-bottom: 175px;
}
.pb-180 {
	padding-bottom: 180px;
}
.pb-185 {
	padding-bottom: 185px;
}
.pb-190 {
	padding-bottom: 190px;
}
.pb-195 {
	padding-bottom: 195px;
}
.pb-200 {
	padding-bottom: 200px;
}

.pb-240 {
    padding-bottom: 450px;
}

/*-- Padding Left --*/
.pl-0 {
	padding-left: 0px;
}
.pl-5 {
	padding-left: 5px;
}
.pl-10 {
	padding-left: 10px;
}
.pl-15 {
	padding-left: 15px;
}
.pl-20{
	padding-left: 20px;
}
.pl-25 {
	padding-left: 35px;
}
.pl-30 {
	padding-left: 30px;
}
.pl-35 {
	padding-left: 35px;
}

.pl-35 {
	padding-left: 35px;
}

.pl-40 {
	padding-left: 40px;
}

.pl-45 {
	padding-left: 45px;
}

.pl-50 {
	padding-left: 50px;
}

.pl-55 {
	padding-left: 55px;
}

.pl-60 {
	padding-left: 60px;
}
.pl-65 {
	padding-left: 65px;
}
.pl-70 {
	padding-left: 70px;
}
.pl-75 {
	padding-left: 75px;
}
.pl-80 {
	padding-left: 80px;
}
.pl-85 {
	padding-left: 80px;
}
.pl-90 {
	padding-left: 90px;
}
.pl-95 {
	padding-left: 95px;
}
.pl-100 {
	padding-left: 100px;
}


/*-- Padding Right --*/
.pr-0 {
	padding-right: 0px;
}
.pr-5 {
	padding-right: 5px;
}
.pr-10 {
	padding-right: 10px;
}
.pr-15 {
	padding-right: 15px;
}
.pr-20{
	padding-right: 20px;
}
.pr-25 {
	padding-right: 35px;
}
.pr-30 {
	padding-right: 30px;
}
.pr-35 {
	padding-right: 35px;
}

.pr-35 {
	padding-right: 35px;
}

.pr-40 {
	padding-right: 40px;
}

.pr-45 {
	padding-right: 45px;
}

.pr-50 {
	padding-right: 50px;
}

.pr-55 {
	padding-right: 55px;
}

.pr-60 {
	padding-right: 60px;
}
.pr-65 {
	padding-right: 65px;
}
.pr-70 {
	padding-right: 70px;
}
.pr-75 {
	padding-right: 75px;
}
.pr-80 {
	padding-right: 80px;
}
.pr-85 {
	padding-right: 80px;
}
.pr-90 {
	padding-right: 90px;
}
.pr-95 {
	padding-right: 95px;
}
.pr-100 {
	padding-right: 100px;
}
/*-- margin Right --*/
.mr-40{
    margin-right: 40px;
}
/* font weight */
.f-700{font-weight: 600;}
.f-600{font-weight: 600;}
.f-500{font-weight: 500;}
.f-400{font-weight: 400;}
.f-300{font-weight: 300;}

/* Background Color */

.gray-bg {
	background: #f4f4fe;
}
.white-bg {
	background: #fff;
}
.black-bg {
	background: #222;
}
.theme-bg {
	background: #222;
}
.primary-bg {
	background: #1a237e;
}
/* Color */

.white-color {
	color: #fff;
}
.black-color {
	color: #222;
}
.theme-color {
	color: #222;
}
.primary-color {
	color: #222;
}
/* black overlay */

[data-overlay] {
	position: relative;
}
[data-overlay]::before {
	background: #000 none repeat scroll 0 0;
	content: "";
	height: 100%;
	left: 0;
	position: absolute;
	top: 0;
	width: 100%;
	z-index: 1;
}
[data-overlay="3"]::before {
	opacity: 0.3;
}
[data-overlay="4"]::before {
	opacity: 0.4;
}
[data-overlay="5"]::before {
	opacity: 0.5;
}
[data-overlay="6"]::before {
	opacity: 0.6;
}
[data-overlay="7"]::before {
	opacity: 0.7;
}
[data-overlay="8"]::before {
	opacity: 0.8;
}
[data-overlay="9"]::before {
	opacity: 0.9;
}


.text-right{
text-align: right;
}
a {
    text-decoration: none;
}
/*********************** Demo - 20 *******************/
.box20{position:relative}
.box20:after,.box20:before{position:absolute;content:""}
.box20:before{width:80%;height:220%;background:#df2919;top:-50%;left:-100%;z-index:1;transform:rotate(25deg);transform-origin:center top 0;transition:all .5s ease 0s}
.box20:hover:before{left:10%}
.box20:after{width:55%;height:175%;background-color:rgba(0,0,0,.8);bottom:-1000%;left:53%;transform:rotate(-33deg);transform-origin:center bottom 0;transition:all .8s ease 0s}
.box20 .box-content,.box20 .icon{width:100%;padding:0 20px;position:absolute;left:0;z-index:2;transition:all 1.1s ease 0s}
.box20:hover:after{bottom:-70%}
.box20 img{width:100%;height:auto}
.box20 .box-content{top:-100%;color:#fff}
.box20:hover .box-content{top:30px}
.box20 .title{font-size:24px;margin:0; color: #fff;}
.box20 .icon li a,.box20 .post{display:inline-block;font-size:14px}
.box20 .post{margin-top:5px}
.box20 .icon{list-style:none;margin:0;bottom:-100%}
.box20:hover .icon{bottom:25px}
.box20 .icon li{display:inline-block}
.box20 .icon li a{width:35px;height:35px;line-height:35px;background:#444;border-radius:50%;margin:0 3px;color:#fff;text-align:center;transition:all .5s ease 0s}
.box20 .icon li a:hover{background:#fff;color:#ff402a}
@media only screen and (max-width:990px){.box20{margin-bottom:30px}
}
@media only screen and (max-width:479px){.box20 .title{font-size:20px}
}



 /* The flip card container - set the width and height to whatever you want. We have added the border property to demonstrate that the flip itself goes out of the box on hover (remove perspective if you don't want the 3D effect */
.flip-card {
  background-color: transparent;
  width: 100%;
  height: 352px;
  perspective: 1000px; /* Remove this if you don't want the 3D effect */
}

/* This container is needed to position the front and back side */
.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.8s;
  transform-style: preserve-3d;
}

/* Do an horizontal flip when you move the mouse over the flip box container */
.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}

/* Position the front and back side */
.flip-card-front, .flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden; /* Safari */
  backface-visibility: hidden;
}

/* Style the front side (fallback if image is missing) */
.flip-card-front {
  background-color: #bbb;
  color: black;
}

/* Style the back side */
.flip-card-back {
  background-color: #df2919;
  color: white;
  transform: rotateY(180deg);
} 
.flip-card-back h4{
    color: #fff;
}
.flip-card-back h4 a:hover{
    color: #fff;
}
.flip-card-back .social{
    margin-top: 10px;
}

.flip-card-back .social a {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: #fff;
    display: inline-block;
    line-height: 40px;
    color: #df2919;
}
.flip-card-back .social a:hover {
    background: #001659;
    display: inline-block;
    line-height: 40px;
    color: #fff;
}
/*ZoomIn Hover Effect*/
    .hover-zoomin a {
      display: block;
      position: relative;
      overflow: hidden;
    }
    .hover-zoomin img {
      width: 100%;
      height: auto;
      -webkit-transition: all 0.5s ease-in-out;
      -moz-transition: all 0.5s ease-in-out;
      -o-transition: all 0.5s ease-in-out;
      -ms-transition: all 0.5s ease-in-out;
      transition: all 0.5s ease-in-out;
    }
    .hover-zoomin:hover img {
      -webkit-transform: scale(1.2);
      -moz-transform: scale(1.2);
      -o-transform: scale(1.2);
      -ms-transform: scale(1.2);
      transform: scale(1.2);
    }


@keyframes rotateme {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes rotateme {
  from {
    -webkit-transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
  }
}

@-moz-keyframes rotateme {
  from {
    -moz-transform: rotate(0deg);
  }

  to {
    -moz-transform: rotate(360deg);
  }
}

@-o-keyframes rotateme {
  from {
    -o-transform: rotate(0deg);
  }

  to {
    -o-transform: rotate(360deg);
  }
}


.rotate-me {
  animation-name: rotateme;
  animation-duration: 24s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;

  -webkit-animation-name: rotateme;
  -webkit-animation-duration: 24s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;

  -moz-animation-name: rotateme;
  -moz-animation-duration: 24s;
  -moz-animation-iteration-count: infinite;
  -moz-animation-timing-function: linear;

  -ms-animation-name: rotateme;
  -ms-animation-duration: 24s;
  -ms-animation-iteration-count: infinite;
  -ms-animation-timing-function: linear;

  -o-animation-name: rotateme;
  -o-animation-duration: 24s;
  -o-animation-iteration-count: infinite;
  -o-animation-timing-function: linear;
}



@-webkit-keyframes shine {
  100% {
    left: 125%;
  }
}

@keyframes shine {
  100% {
    left: 125%;
  }
}



@-webkit-keyframes float-bob {
  0% {
    -webkit-transform: translateX(-100px);
    transform: translateX(-100px);
  }

  50% {
    -webkit-transform: translateX(-10px);
    transform: translateX(-10px);
  }

  100% {
    -webkit-transform: translateX(-100px);
    transform: translateX(-100px);
  }
}

@keyframes float-bob {
  0% {
    -webkit-transform: translateX(-100px);
    transform: translateX(-100px);
  }

  50% {
    -webkit-transform: translateX(-10px);
    transform: translateX(-10px);
  }

  100% {
    -webkit-transform: translateX(-100px);
    transform: translateX(-100px);
  }
}




.float-bob {
  animation-name: float-bob;
  animation-duration: 7s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;

  -webkit-animation-name: float-bob;
  -webkit-animation-duration: 7s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;

  -moz-animation-name: float-bob;
  -moz-animation-duration: 7s;
  -moz-animation-iteration-count: infinite;
  -moz-animation-timing-function: linear;

  -ms-animation-name: float-bob;
  -ms-animation-duration: 7s;
  -ms-animation-iteration-count: infinite;
  -ms-animation-timing-function: linear;

  -o-animation-name: float-bob;
  -o-animation-duration: 7s;
  -o-animation-iteration-count: infinite;
  -o-animation-timing-function: linear;
}







/*** 
=============================================
    circle Css
=============================================
***/
@-webkit-keyframes circle {
  0% {
    opacity: 1;
  }

  40% {
    opacity: 1;
  }

  100% {
    width: 200%;
    height: 200%;
    opacity: 0;
  }
}

@keyframes circle {
  0% {
    opacity: 1;
  }

  40% {
    opacity: 1;
  }

  100% {
    width: 200%;
    height: 200%;
    opacity: 0;
  }
}

/*** 
=============================================
    Zoom In Out Css
=============================================
***/
.zoominout {
  animation-name: zoomInOut;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;

  -webkit-animation-name: zoomInOut;
  -webkit-animation-duration: 3s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: ease-in-out;

  -moz-animation-name: zoomInOut;
  -moz-animation-duration: 3s;
  -moz-animation-iteration-count: infinite;
  -moz-animation-timing-function: ease-in-out;

  -ms-animation-name: zoomInOut;
  -ms-animation-duration: 3s;
  -ms-animation-iteration-count: infinite;
  -ms-animation-timing-function: ease-in-out;

  -o-animation-name: zoomInOut;
  -o-animation-duration: 3s;
  -o-animation-iteration-count: infinite;
  -o-animation-timing-function: ease-in-out;
}

@-webkit-keyframes zoomInOut {
  0% {
    -webkit-transform: rotate(0deg) scale(0.7);
    transform: rotate(0deg) scale(0.7);
    opacity: 0;
  }

  50% {
    -webkit-transform: rotate(180deg) scale(1);
    transform: rotate(180deg) scale(1);
    opacity: 1;
  }

  100% {
    -webkit-transform: rotate(360deg) scale(0.7);
    transform: rotate(360deg) scale(0.7);
    opacity: 0;
  }
}




/*** 
=============================================
    Zoom In Out Css
=============================================
***/
.zoominout-2 {
  animation-name: zoomInOut-2;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;

  -webkit-animation-name: zoomInOut-2;
  -webkit-animation-duration: 3s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: ease-in-out;

  -moz-animation-name: zoomInOut-2;
  -moz-animation-duration: 3s;
  -moz-animation-iteration-count: infinite;
  -moz-animation-timing-function: ease-in-out;

  -ms-animation-name: zoomInOut-2;
  -ms-animation-duration: 3s;
  -ms-animation-iteration-count: infinite;
  -ms-animation-timing-function: ease-in-out;

  -o-animation-name: zoomInOut-2;
  -o-animation-duration: 3s;
  -o-animation-iteration-count: infinite;
  -o-animation-timing-function: ease-in-out;
}

@-webkit-keyframes zoomInOut-2 {
  0% {
    -webkit-transform: rotate(0deg) scale(0.7);
    transform: rotate(0deg) scale(0.7);
    opacity: .50;
  }

  50% {
    -webkit-transform: rotate(0deg) scale(1);
    transform: rotate(0deg) scale(1);
    opacity: .50;
  }

  100% {
    -webkit-transform: rotate(0deg) scale(0.7);
    transform: rotate(0deg) scale(0.7);
    opacity: .50;
  }
}





@-webkit-keyframes bgSlideReverse {
  0% {
    background-position: 0 0;
  }

  20% {
    background-position: 100px 0;
  }

  40% {
    background-position: 200px 0;
  }

  60% {
    background-position: 150px 0;
  }

  80% {
    background-position: 100px 0;
  }

  100% {
    background-position: 0px 0;
  }
}

@keyframes bgSlideReverse {
  0% {
    background-position: 0 0;
  }

  20% {
    background-position: 100px 0;
  }

  40% {
    background-position: 200px 0;
  }

  60% {
    background-position: 150px 0;
  }

  80% {
    background-position: 100px 0;
  }

  100% {
    background-position: 0px 0;
  }
}



@-webkit-keyframes bgSlideReverse2 {
  0% {
    background-position: -0 0;
  }

  20% {
    background-position: -100px 0;
  }

  40% {
    background-position: -200px 0;
  }

  60% {
    background-position: -150px 0;
  }

  80% {
    background-position: -100px 0;
  }

  100% {
    background-position: -0px 0;
  }
}

@keyframes bgSlideReverse2 {
  0% {
    background-position: -0 0;
  }

  20% {
    background-position: -100px 0;
  }

  40% {
    background-position: -200px 0;
  }

  60% {
    background-position: -150px 0;
  }

  80% {
    background-position: -100px 0;
  }

  100% {
    background-position: -0px 0;
  }
}






@-webkit-keyframes banner-animate {
  0% {
    -webkit-transform: translateX(-1000px);
    transform: translateX(-1000px);
  }

  50% {
    -webkit-transform: translateX(-10px);
    transform: translateX(-10px);
  }

  100% {
    -webkit-transform: translateX(-1000px);
    transform: translateX(-1000px);
  }
}

@keyframes banner-animate {
  0% {
    -webkit-transform: translateX(-1000px);
    transform: translateX(-1000px);
  }

  50% {
    -webkit-transform: translateX(-10px);
    transform: translateX(-10px);
  }

  100% {
    -webkit-transform: translateX(-1000px);
    transform: translateX(-1000px);
  }
}

.banner-animate {
  animation-name: banner-animate;
  animation-duration: 70s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  -webkit-animation-name: banner-animate;
  -webkit-animation-duration: 70s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;

  -moz-animation-name: banner-animate;
  -moz-animation-duration: 70s;
  -moz-animation-iteration-count: infinite;
  -moz-animation-timing-function: linear;

  -ms-animation-name: banner-animate;
  -ms-animation-duration: 70s;
  -ms-animation-iteration-count: infinite;
  -ms-animation-timing-function: linear;

  -o-animation-name: banner-animate;
  -o-animation-duration: 70s;
  -o-animation-iteration-count: infinite;
  -o-animation-timing-function: linear;
}







@-webkit-keyframes ripple {
  70% {
    box-shadow: 0 0 0 40px rgba(10, 165, 205, 0);
  }

  100% {
    box-shadow: 0 0 0 0 rgba(10, 165, 205, 0);
  }
}

@keyframes ripple {
  70% {
    box-shadow: 0 0 0 40px rgba(10, 165, 205, 0);
  }

  100% {
    box-shadow: 0 0 0 0 rgba(10, 165, 205, 0);
  }
}


/*** 
=============================================
    Animation1 Css
=============================================
***/
@-webkit-keyframes animation1 {

  0%,
  100% {
    -webkit-transform: rotate(0deg) translateX(0);
    transform: rotate(0deg) translateX(0);
  }

  25%,
  75% {
    -webkit-transform: rotate(5deg) translateX(15px);
    transform: rotate(5deg) translateX(15px);
  }

  50% {
    -webkit-transform: rotate(10deg) translateX(30px);
    transform: rotate(10deg) translateX(30px);
  }
}

@keyframes animation1 {

  0%,
  100% {
    -webkit-transform: rotate(0deg) translateX(0);
    transform: rotate(0deg) translateX(0);
  }

  25%,
  75% {
    -webkit-transform: rotate(5deg) translateX(15px);
    transform: rotate(5deg) translateX(15px);
  }

  50% {
    -webkit-transform: rotate(10deg) translateX(30px);
    transform: rotate(10deg) translateX(30px);
  }
}



/*** 
=============================================
    Animation2 Css
=============================================
***/
@animation2 {
  0% {
    -webkit-transform: rotateY(0deg);
    transform: rotateY(0deg)
  }

  100% {
    -webkit-transform: rotateY(360deg);
    transform: rotateY(360deg)
  }
}

@-moz-keyframes animation2 {
  0% {
    -moz-transform: rotateY(0deg);
    transform: rotateY(0deg)
  }

  100% {
    -moz-transform: rotateY(360deg);
    transform: rotateY(360deg)
  }
}

@-o-keyframes animation2 {
  0% {
    -o-transform: rotateY(0deg);
    transform: rotateY(0deg)
  }

  100% {
    -o-transform: rotateY(360deg);
    transform: rotateY(360deg)
  }
}

@keyframes animation2 {
  0% {
    -webkit-transform: rotateY(0deg);
    -moz-transform: rotateY(0deg);
    -o-transform: rotateY(0deg);
    transform: rotateY(0deg)
  }

  100% {
    -webkit-transform: rotateY(360deg);
    -moz-transform: rotateY(360deg);
    -o-transform: rotateY(360deg);
    transform: rotateY(360deg)
  }
}









@-webkit-keyframes bgSlide {
  0% {
    background-position: 0 0;
  }

  20% {
    background-position: -100px 0;
  }

  40% {
    background-position: -200px 0;
  }

  60% {
    background-position: -150px 0;
  }

  80% {
    background-position: -100px 0;
  }

  100% {
    background-position: 0px 0;
  }
}


@keyframes bgSlide {
  0% {
    background-position: 0 0;
  }

  20% {
    background-position: -100px 0;
  }

  40% {
    background-position: -200px 0;
  }

  60% {
    background-position: -150px 0;
  }

  80% {
    background-position: -100px 0;
  }

  100% {
    background-position: 0px 0;
  }
}

@-webkit-keyframes bgSlideReverse {
  0% {
    background-position: 0 0;
  }

  20% {
    background-position: 100px 0;
  }

  40% {
    background-position: 200px 0;
  }

  60% {
    background-position: 150px 0;
  }

  80% {
    background-position: 100px 0;
  }

  100% {
    background-position: 0px 0;
  }
}

@keyframes bgSlideReverse {
  0% {
    background-position: 0 0;
  }

  20% {
    background-position: 100px 0;
  }

  40% {
    background-position: 200px 0;
  }

  60% {
    background-position: 150px 0;
  }

  80% {
    background-position: 100px 0;
  }

  100% {
    background-position: 0px 0;
  }
}

@-webkit-keyframes ribbonRotate {

  0%,
  100% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }

  25%,
  75% {
    -webkit-transform: rotate(15deg);
    transform: rotate(15deg);
  }

  50% {
    -webkit-transform: rotate(30deg);
    transform: rotate(30deg);
  }
}

@keyframes ribbonRotate {

  0%,
  100% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }

  25%,
  75% {
    -webkit-transform: rotate(15deg);
    transform: rotate(15deg);
  }

  50% {
    -webkit-transform: rotate(30deg);
    transform: rotate(30deg);
  }
}



@-webkit-keyframes footerFish {

  0%,
  100% {
    -webkit-transform: translateX(0) translateY(0) rotate(0);
    transform: translateX(0) translateY(0) rotate(0);
  }

  25%,
  75% {
    -webkit-transform: translateX(20px) translateY(30px) rotate(25deg);
    transform: translateX(20px) translateY(30px) rotate(25deg);
  }

  50% {
    -webkit-transform: translateX(40px) translateY(60px) rotate(45deg);
    transform: translateX(40px) translateY(60px) rotate(45deg);
  }
}

@keyframes footerFish {

  0%,
  100% {
    -webkit-transform: translateX(0) translateY(0) rotate(0);
    transform: translateX(0) translateY(0) rotate(0);
  }

  25%,
  75% {
    -webkit-transform: translateX(20px) translateY(30px) rotate(25deg);
    transform: translateX(20px) translateY(30px) rotate(25deg);
  }

  50% {
    -webkit-transform: translateX(40px) translateY(60px) rotate(45deg);
    transform: translateX(40px) translateY(60px) rotate(45deg);
  }
}

@-webkit-keyframes contactSwimmer {

  0%,
  100% {
    -webkit-transform: translateX(0) translateY(0) rotate(0);
    transform: translateX(0) translateY(0) rotate(0);
  }

  25%,
  75% {
    -webkit-transform: translateX(15px) translateY(20px) rotate(10deg);
    transform: translateX(15px) translateY(20px) rotate(10deg);
  }

  50% {
    -webkit-transform: translateX(60px) translateY(35px) rotate(15deg);
    transform: translateX(60px) translateY(35px) rotate(15deg);
  }
}

@keyframes contactSwimmer {

  0%,
  100% {
    -webkit-transform: translateX(0) translateY(0) rotate(0);
    transform: translateX(0) translateY(0) rotate(0);
  }

  25%,
  75% {
    -webkit-transform: translateX(15px) translateY(20px) rotate(10deg);
    transform: translateX(15px) translateY(20px) rotate(10deg);
  }

  50% {
    -webkit-transform: translateX(60px) translateY(35px) rotate(15deg);
    transform: translateX(60px) translateY(35px) rotate(15deg);
  }
}




/*** 
=============================================
    Float Bob Y Animation Css
=============================================
***/
@-webkit-keyframes float-bob-y {
  0% {
    transform: translateY(-20px);
  }

  50% {
    transform: translateY(-10px);
  }

  100% {
    transform: translateY(-20px);
  }
}

@keyframes float-bob-y {
  0% {
    transform: translateY(-20px);
  }

  50% {
    transform: translateY(-10px);
  }

  100% {
    transform: translateY(-20px);
  }
}

.float-bob-y {
  -webkit-animation-name: float-bob-y;
  animation-name: float-bob-y;
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
}



@-webkit-keyframes footerTree {

  0%,
  100% {
    -webkit-transform: rotate(0deg) translateX(0);
    transform: rotate(0deg) translateX(0);
  }

  25%,
  75% {
    -webkit-transform: rotate(5deg) translateX(15px);
    transform: rotate(5deg) translateX(15px);
  }

  50% {
    -webkit-transform: rotate(10deg) translateX(30px);
    transform: rotate(10deg) translateX(30px);
  }
}

@keyframes footerTree {

  0%,
  100% {
    -webkit-transform: rotate(0deg) translateX(0);
    transform: rotate(0deg) translateX(0);
  }

  25%,
  75% {
    -webkit-transform: rotate(5deg) translateX(15px);
    transform: rotate(5deg) translateX(15px);
  }

  50% {
    -webkit-transform: rotate(10deg) translateX(30px);
    transform: rotate(10deg) translateX(30px);
  }
}

.footertree {
  -webkit-animation: footerTree 5s ease-in infinite;
  animation: footerTree 5s ease-in infinite;
}



@service_hexagon_2 {
  0% {
    -webkit-transform: rotateY(0deg);
    transform: rotateY(0deg)
  }

  100% {
    -webkit-transform: rotateY(360deg);
    transform: rotateY(360deg)
  }
}

@-moz-keyframes service_hexagon_2 {
  0% {
    -moz-transform: rotateY(0deg);
    transform: rotateY(0deg)
  }

  100% {
    -moz-transform: rotateY(360deg);
    transform: rotateY(360deg)
  }
}

@-o-keyframes service_hexagon_2 {
  0% {
    -o-transform: rotateY(0deg);
    transform: rotateY(0deg)
  }

  100% {
    -o-transform: rotateY(360deg);
    transform: rotateY(360deg)
  }
}

@keyframes service_hexagon_2 {
  0% {
    -webkit-transform: rotateY(0deg);
    -moz-transform: rotateY(0deg);
    -o-transform: rotateY(0deg);
    transform: rotateY(0deg)
  }

  100% {
    -webkit-transform: rotateY(360deg);
    -moz-transform: rotateY(360deg);
    -o-transform: rotateY(360deg);
    transform: rotateY(360deg)
  }
}



@keyframes bgSlide {
  0% {
    background-position: 0 0;
  }

  20% {
    background-position: -100px 0;
  }

  40% {
    background-position: -200px 0;
  }

  60% {
    background-position: -150px 0;
  }

  80% {
    background-position: -100px 0;
  }

  100% {
    background-position: 0px 0;
  }
}











@keyframes rotateScale {
  from {
    transform: rotate(0deg) scale(1);
  }

  to {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes rotateScale {
  from {
    transform: rotate(0deg) scale(1);
  }

  to {
    transform: rotate(360deg);
  }
}

@-moz-keyframes rotateScale {
  from {
    transform: rotate(0deg) scale(1);
  }

  to {
    transform: rotate(360deg);
  }
}

@-o-keyframes rotateScale {
  from {
    transform: rotate(0deg) scale(1);
  }

  to {
    transform: rotate(360deg);
  }
}

.rotatescale {
  -webkit-animation-name: rotateScale;
  -webkit-animation-duration: 20s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;

  -moz-animation-name: rotateScale;
  -moz-animation-duration: 20s;
  -moz-animation-iteration-count: infinite;
  -moz-animation-timing-function: linear;

  -ms-animation-name: rotateScale;
  -ms-animation-duration: 20s;
  -ms-animation-iteration-count: infinite;
  -ms-animation-timing-function: linear;

  animation-name: rotateScale;
  animation-duration: 20s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

/*--------------------------------------------------------------
# Animations
--------------------------------------------------------------*/
@-webkit-keyframes bubbleMover {
  0% {
    transform: translateY(0px) translateX(0) rotate(0);
  }

  30% {
    transform: translateY(30px) translateX(50px) rotate(15deg);
    transform-origin: center center;
  }

  50% {
    transform: translateY(50px) translateX(100px) rotate(45deg);
    transform-origin: right bottom;
  }

  80% {
    transform: translateY(30px) translateX(50px) rotate(15deg);
    transform-origin: left top;
  }

  100% {
    transform: translateY(0px) translateX(0) rotate(0);
    transform-origin: center center;
  }
}

@keyframes bubbleMover {
  0% {
    transform: translateY(0px) translateX(0) rotate(0);
  }

  30% {
    transform: translateY(30px) translateX(50px) rotate(15deg);
    transform-origin: center center;
  }

  50% {
    transform: translateY(50px) translateX(100px) rotate(45deg);
    transform-origin: right bottom;
  }

  80% {
    transform: translateY(30px) translateX(50px) rotate(15deg);
    transform-origin: left top;
  }

  100% {
    transform: translateY(0px) translateX(0) rotate(0);
    transform-origin: center center;
  }
}

@-webkit-keyframes shapeMover {

  0%,
  100% {
    transform: perspective(400px) translateY(0) rotate(0deg) translateZ(0px) translateX(0);
  }

  50% {
    transform: perspective(400px) rotate(0deg) translateZ(20px) translateY(20px) translateX(20px);
  }
}

@keyframes shapeMover {

  0%,
  100% {
    transform: perspective(400px) translateY(0) rotate(0deg) translateZ(0px) translateX(0);
  }

  50% {
    transform: perspective(400px) rotate(0deg) translateZ(20px) translateY(20px) translateX(20px);
  }
}



@-webkit-keyframes shapeMover {

  0%,
  100% {
    -webkit-transform: perspective(400px) translateY(0) rotate(0deg) translateZ(0px) translateX(0);
    transform: perspective(400px) translateY(0) rotate(0deg) translateZ(0px) translateX(0);
  }

  50% {
    -webkit-transform: perspective(400px) rotate(-45deg) translateZ(20px) translateY(20px) translateX(20px);
    transform: perspective(400px) rotate(-45deg) translateZ(20px) translateY(20px) translateX(20px);
  }
}


@keyframes shapeMover-2 {

  0%,
  100% {
    -webkit-transform: perspective(400px) translateY(0) rotate(0deg) translateZ(0px) translateX(0);
    transform: perspective(400px) translateY(0) rotate(0deg) translateZ(0px) translateX(0);
  }

  50% {
    -webkit-transform: perspective(400px) rotate(-45deg) translateZ(20px) translateY(20px) translateX(20px);
    transform: perspective(400px) rotate(-45deg) translateZ(20px) translateY(20px) translateX(20px);
  }
}

.shape-mover {
  -webkit-animation-name: shapeMover;
  animation-name: shapeMover;
  -webkit-animation-duration: 9s;
  animation-duration: 9s;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}



@-webkit-keyframes banner3Shake {
  0% {
    transform: rotate3d(0, 1, 0, 0deg);
  }

  30% {
    transform: rotate3d(0, 0, 1, 5deg);
  }

  60% {
    transform: rotate3d(1, 0, 0, 0deg);
  }

  80% {
    transform: rotate3d(0, 0, 1, 5deg);
  }

  100% {
    transform: rotate3d(0, 1, 0, 0deg);
  }
}



@keyframes banner3Shake {
  0% {
    -webkit-transform: rotate3d(0, 1, 0, 0deg);
    transform: rotate3d(0, 1, 0, 0deg);
  }

  30% {
    -webkit-transform: rotate3d(0, 0, 1, 15deg);
    transform: rotate3d(0, 0, 1, 15deg);
  }

  60% {
    -webkit-transform: rotate3d(1, 0, 0, 0deg);
    transform: rotate3d(1, 0, 0, 0deg);
  }

  80% {
    -webkit-transform: rotate3d(0, 0, 1, 15deg);
    transform: rotate3d(0, 0, 1, 15deg);
  }

  100% {
    -webkit-transform: rotate3d(0, 1, 0, 0deg);
    transform: rotate3d(0, 1, 0, 0deg);
  }

}


.banner-3-Shake {
  -webkit-transform-origin: center bottom;
  transform-origin: center bottom;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-name: banner3Shake;
  animation-name: banner3Shake;
  -webkit-animation-timing-function: ease-out;
  animation-timing-function: ease-out;
  -webkit-animation-duration: 5s;
  animation-duration: 5s;
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}



@-webkit-keyframes squareMover {

  0%,
  100% {
    transform: translate(0, 0) rotate(0);
  }

  20%,
  60% {
    transform: translate(20px, 40px) rotate(180deg);
  }

  30%,
  80% {
    transform: translate(40px, 60px) rotate(0deg);
  }
}

@keyframes squareMover {

  0%,
  100% {
    transform: translate(0, 0) rotate(0);
  }

  20%,
  60% {
    transform: translate(20px, 40px) rotate(180deg);
  }

  30%,
  80% {
    transform: translate(40px, 60px) rotate(0deg);
  }
}

@-webkit-keyframes treeMove {

  0%,
  100% {
    transform: rotate(0deg) translateX(0);
  }

  25%,
  75% {
    transform: rotate(5deg) translateX(15px);
  }

  50% {
    transform: rotate(10deg) translateX(30px);
  }
}

@keyframes treeMove {

  0%,
  100% {
    transform: rotate(0deg) translateX(0);
  }

  25%,
  75% {
    transform: rotate(5deg) translateX(15px);
  }

  50% {
    transform: rotate(10deg) translateX(30px);
  }
}


/*--------------------------------------------------------------
#     Zoom Fade Css
--------------------------------------------------------------*/
.zoom-fade {
  animation-name: zoom-fade;
  animation-duration: 5s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;

  -webkit-animation-name: zoom-fade;
  -webkit-animation-duration: 5s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;

  -moz-animation-name: zoom-fade;
  -moz-animation-duration: 5s;
  -moz-animation-iteration-count: infinite;
  -moz-animation-timing-function: linear;

  -ms-animation-name: zoom-fade;
  -ms-animation-duration: 5s;
  -ms-animation-iteration-count: infinite;
  -ms-animation-timing-function: linear;

  -o-animation-name: zoom-fade;
  -o-animation-duration: 5s;
  -o-animation-iteration-count: infinite;
  -o-animation-timing-function: linear;
}

@-webkit-keyframes zoom-fade {
  0% {
    transform: scale(0.9) translateX(-50%);
  }

  50% {
    transform: scale(1) translateX(-50%);
  }

  100% {
    transform: scale(0.9) translateX(-50%);
  }
}

@keyframes zoom-fade {
  0% {
    transform: scale(0.9) translateX(-50%);
  }

  50% {
    transform: scale(1) translateX(-50%);
  }

  100% {
    transform: scale(0.9) translateX(-50%);
  }
}


/*--------------------------------------------------------------
#     Zoom Fade 2 Css
--------------------------------------------------------------*/
.zoom-fade-2 {
  animation-name: zoom-fade-2;
  animation-duration: 8s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;

  -webkit-animation-name: zoom-fade-2;
  -webkit-animation-duration: 8s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;

  -moz-animation-name: zoom-fade-2;
  -moz-animation-duration: 8s;
  -moz-animation-iteration-count: infinite;
  -moz-animation-timing-function: linear;

  -ms-animation-name: zoom-fade-2;
  -ms-animation-duration: 8s;
  -ms-animation-iteration-count: infinite;
  -ms-animation-timing-function: linear;

  -o-animation-name: zoom-fade-2;
  -o-animation-duration: 8s;
  -o-animation-iteration-count: infinite;
  -o-animation-timing-function: linear;
}

@-webkit-keyframes zoom-fade-2 {
  0% {
    transform: scale(0.9) translateX(0) translateY(0);
  }

  50% {
    transform: scale(1) translateX(0) translateY(0);
  }

  100% {
    transform: scale(0.9) translateX(0) translateY(0);
  }
}

@keyframes zoom-fade-2 {
  0% {
    transform: scale(0.9) translateX(0) translateY(0);
  }

  50% {
    transform: scale(1) translateX(0) translateY(0);
  }

  100% {
    transform: scale(0.9) translateX(0) translateY(0);
  }
}





/*--------------------------------------------------------------
#     Zoom Fade 3 Css
--------------------------------------------------------------*/
.zoom-fade-3 {
  animation-name: zoom-fade-3;
  animation-duration: 5s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;

  -webkit-animation-name: zoom-fade-3;
  -webkit-animation-duration: 5s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;

  -moz-animation-name: zoom-fade-3;
  -moz-animation-duration: 5s;
  -moz-animation-iteration-count: infinite;
  -moz-animation-timing-function: linear;

  -ms-animation-name: zoom-fade-3;
  -ms-animation-duration: 5s;
  -ms-animation-iteration-count: infinite;
  -ms-animation-timing-function: linear;

  -o-animation-name: zoom-fade-3;
  -o-animation-duration: 5s;
  -o-animation-iteration-count: infinite;
  -o-animation-timing-function: linear;
}



/* custom animations */

@keyframes btnIconMove {
  0% {
    transform: rotate(0deg) translate(0px, 0px);
  }

  50% {
    transform: rotate(20deg) translate(10px, 0px);
  }

  100% {
    transform: rotate(0deg) translate(0px, 0px);
  }
}




@-webkit-keyframes ImgBounce {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  100% {
    -webkit-transform: translateY(-30px);
    transform: translateY(-30px);
  }
}

@keyframes ImgBounce {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  100% {
    -webkit-transform: translateY(-30px);
    transform: translateY(-30px);
  }
}


.img-bounce {
  -webkit-animation: ImgBounce 5s ease-in-out 0s infinite alternate;
  animation: ImgBounce 5s ease-in-out 0s infinite alternate;
}



/** squares **/

@-moz-keyframessquares {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0
  }

  20% {
    -webkit-transform: scale(1.24);
    transform: scale(1.24);
    opacity: 1
  }

  100% {
    -webkit-transform: scale(2.1);
    transform: scale(2.1);
    opacity: 0
  }
}

@-moz-keyframes squares {
  0% {
    -moz-transform: scale(1);
    transform: scale(1);
    opacity: 0
  }

  20% {
    -moz-transform: scale(1.24);
    transform: scale(1.24);
    opacity: 1
  }

  100% {
    -moz-transform: scale(2.1);
    transform: scale(2.1);
    opacity: 0
  }
}

@-o-keyframes squares {
  0% {
    -o-transform: scale(1);
    transform: scale(1);
    opacity: 0
  }

  20% {
    -o-transform: scale(1.24);
    transform: scale(1.24);
    opacity: 1
  }

  100% {
    -o-transform: scale(2.1);
    transform: scale(2.1);
    opacity: 0
  }
}

@keyframes squares {
  0% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
    opacity: 0
  }

  20% {
    -webkit-transform: scale(1.24);
    -moz-transform: scale(1.24);
    -o-transform: scale(1.24);
    transform: scale(1.24);
    opacity: 1
  }

  100% {
    -webkit-transform: scale(2.1);
    -moz-transform: scale(2.1);
    -o-transform: scale(2.1);
    transform: scale(2.1);
    opacity: 0
  }
}

/***
=============================================
    Float Bob X Animation Css
=============================================
***/
@-webkit-keyframes float-bob-x {
  0% {
    transform: translateX(-30px);
  }

  50% {
    transform: translateX(-10px);
  }

  100% {
    transform: translateX(-30px);
  }
}

@keyframes float-bob-x {
  0% {
    transform: translateX(-30px);
  }

  50% {
    transform: translateX(-10px);
  }

  100% {
    transform: translateX(-30px);
  }
}

.float-bob-x {
  -webkit-animation-name: float-bob-x;
  animation-name: float-bob-x;
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
}


/*** 
=============================================
    Float Bob Y Animation Css
=============================================
***/
@-webkit-keyframes float-bob-y-2 {
  0% {
    transform: translateY(-20px) translateX(-50%) rotate(0deg);
    ;
  }

  50% {
    transform: translateY(-10px) translateX(-50%) rotate(0deg);
    ;
  }

  100% {
    transform: translateY(-20px) translateX(-50%) rotate(0deg);
    ;
  }
}

@keyframes float-bob-y-2 {
  0% {
    transform: translateY(-20px) translateX(-50%) rotate(0deg);
    ;
  }

  50% {
    transform: translateY(-10px) translateX(-50%) rotate(0deg);
    ;
  }

  100% {
    transform: translateY(-20px) translateX(-50%) rotate(0deg);
    ;
  }
}

.float-bob-y-2 {
  -webkit-animation-name: float-bob-y-2;
  animation-name: float-bob-y-2;
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
}

